"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRedirect = exports.redirect = exports.isMultiWordTag = exports.replaceSpacesWithHyphens = exports.replaceSpacesWithUnderscores = exports.replaceHyphensWithUnderscores = exports.replaceUnderscoresWithHyphens = exports.pathsToReplaceUnderscoresWithHyphens = void 0;
exports.pathsToReplaceUnderscoresWithHyphens = [
    "anixe_hellas_privacy",
    "travel_industry_solutions",
    "demo_form",
    "gulf_air_whitepaper",
    "recruitment_specialist",
    "new_destinations",
    "newsletter_form",
    "sub_success",
    "sub_thank_you",
    "supplier_form",
    "thank_you",
    "unsub_success",
];
exports.replaceUnderscoresWithHyphens = (path) => path.split("_").join("-").toLowerCase();
exports.replaceHyphensWithUnderscores = (path) => path.split("-").join("_").toLowerCase();
exports.replaceSpacesWithUnderscores = (path) => path.trim().split(" ").join("_").toLowerCase();
exports.replaceSpacesWithHyphens = (path) => path.trim().split(" ").join("-").toLowerCase();
exports.isMultiWordTag = (tag) => tag.includes("-") || tag.includes("_") || tag.trim().includes(" ");
exports.redirect = (from, to, actions) => {
    actions.createRedirect({
        fromPath: `/${from}`,
        toPath: `/${to}`,
        redirectInBrowser: true,
        isPermanent: true,
    });
};
exports.createRedirect = (path, actions) => {
    exports.redirect(exports.replaceHyphensWithUnderscores(path), exports.replaceUnderscoresWithHyphens(path), actions);
};
